.headerDiv {
    display: flex;
    margin: auto;
    align-items: center;
}

.titleDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
}

.title {
    font-size: 3em;
    color: #eee;
    font-family: "Homenaje", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: #000 0px 0px 10px;
}

.image {
    border-radius: 50%;
    left: 10%;
    padding: 5px;
    width: 100px;
    height: 100px;
}

.card {
    top: 10px;
    justify-content: center;
    align-items: center;
    align-items: center;
    background-color: #272727;
    opacity: 0.9;
    display: none;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    visibility: visible;
    box-shadow: 0px 0px 10px 5px #111;
}

.cardText {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 1.5em;
    padding: 5px;
}

.icon {
    width: 50px;
    height: 50px;
    border-radius: 40%;
    padding: 5px;
    margin-left:  10px;
}


.noSize
{
    margin: 5%;
    text-align: center;
    font-size: 1.5em;
    font-family: "Inter", sans-serif;
    color: #ccc;
    background-color: #555;
    box-shadow: 0px 0px 10px 5px #111;
    border-radius: 10px;
    padding: 5%;
}

.noSizeDescription
{
    margin: 5%;
    text-align: left;
    font-size: 1.3em;
    font-family: "Inter", sans-serif;
    color: #ccc;
    background-color: #555;
    border-radius: 2%;
    padding: 5%;
}

.imageList
{
    display: flex;
    width: 100%;
    padding: 5%;
    flex-direction: row;
    overflow-x: scroll;
}
